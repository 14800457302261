
import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Components/HomePage';
import Privacy from './Components/Privacy';
import Terms from './Components/Terms';
import Unsubscribe from './Components/Unsubscribe';

function App() {
  return (
   <>
   <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='terms' element={<Terms/>} />
        <Route path='privacy' element={<Privacy/>} />
        <Route path='unsubscribe/:id' element={<Unsubscribe/>} />

   </Routes>

   </>
  );
}

export default App;
