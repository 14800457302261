import _superagent from "superagent";
// import superagentPromise from "superagent-promise";
const superagentPromise = require("superagent-promise")
const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = `https://${(window.origin.includes('staging') || window.origin.includes('localhost')) ? `staging.` : window.origin.includes('production') ? `production.` : ``}v3nus.io:3000/`;
// const API_ROOT = `https://${(window.origin.includes('staging') || window.origin.includes('localhost')) ? `staging.pudoapp.com:3001/` : window.origin.includes('production') ? `pudoapp.com:3000/` : `pudoapp.com:3000/`}`;
const API_ROOT = `https://pudoapp.net:3000/`
// https://pudoapp.net:3000/
// https://staging.pudoapp.com:3001/docs

const API_ROOT_IMAGEUPLOAD = `https://pudostaging.s3.us-east-2.amazonaws.com/pudostaging/original/`
const BUCKET_ROOT = `https://${(window.origin.includes('staging') || window.origin.includes('localhost')) ? `v3nusstaging.` : window.origin.includes('production') ? `v3nusproduction.` : `v3nuslive.`}s3.ap-southeast-2.amazonaws.com/uploads/`;
const AUDIO_BUCKET_ROOT = "https://pudostaging.s3.us-east-2.amazonaws.com/pudostaging/"
const API_FILE_ROOT_MEDIUM = `${BUCKET_ROOT}images/medium/`;
const API_FILE_ROOT_ORIGINAL = `${BUCKET_ROOT}images/original/`;
const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}images/small/`;
const API_FILE_ROOT_AUDIO = `${AUDIO_BUCKET_ROOT}audio/`;
const API_FILE_ROOT_VIDEO = `${AUDIO_BUCKET_ROOT}video/`;
const API_FILE_ROOT_DOCUMENTS = `${BUCKET_ROOT}documents/`;

// const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;

let token = null as null | string;
const tokenPlugin = (req: any) => {
  if (token) {
    req.set("token", `${token}`);
  }
};

const requests = {
  del: (url: any) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url: any) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url: any, body: any) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url: any, body: any) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url: any, body: any) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  file: (url: any, key: any, file: any) =>
    superagent.post(`${API_ROOT}${url}`)
      .attach(key, file)
      .use(tokenPlugin)
      .then(responseBody),


};

const Auth = {
  login: (info: any) => requests.post("Admin/login", info),
  signup: (info: any) => requests.post("signup", info),
  forgotPassword: (info: any) => requests.post("password/forgot", info),
  checkOtp: (info: any) => requests.post("check-email-otp", info),
  resetPassword: (info: any) => requests.post("reset/password", info),
  changePassword: (info: any) => requests.put("Admin/change_password", info),
  uploadImage: (key: string, file: any) => requests.file('Upload/do_spaces_file_upload', key, file)
};



const Common = {
  content: (type: any) => requests.get(`User/list_content?type=${type}`),
  unsubscribe: (id: any) => requests.get(`Admin/unsubscribe/${id}`),

}








export default {
  token,
  Auth,
  Common,
  // Nfts,
  API_ROOT,
  API_FILE_ROOT_AUDIO,
  API_ROOT_IMAGEUPLOAD,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  setToken: (_token: any) => {
    token = _token;
  },
};
