import { useEffect, useState } from "react";
import imga from "../assest/images/ic_launcher_foreground.png";
import henceforthApi from "../Utiles/henceforthApi";
import { useParams } from "react-router-dom";
const Unsubscribe = () => {
    const [data, setData] = useState({} as any)
    const { id } = useParams()
    const unsubscribe = async () => {
        try {
            let res = await henceforthApi.Common.unsubscribe(id)
            console.log(res);
        } catch (error) {
        }
    }
    useEffect(() => {
        unsubscribe()
    }, [])
    return (
        <>
            <header>
                <div className="image-box">
                    <div className="h-logo d-flex">
                        <img style={{ height: '100%' }} src={imga} alt="" />
                        <span className="text-white m-l">Pudo</span>
                    </div>
                </div>
            </header>
            <section className="p">
                <div className="card text-center">
                    <p className="heading">You’ve Been Unsubscribed
                    </p>
                    <div>
                        <p className="discription">You’ve successfully unsubscribed from our email list. We’re sorry to see you go, but we respect your decision.</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Unsubscribe