import imga from "../assest/images/ic_launcher_foreground.png";
const HomePage = () => {
    return (
        <>
            {/* <header>
                <div className="image-box">
                    <div className="h-logo d-flex">
                        <img style={{ height: '100%' }} src={imga} alt="" />
                        <span className="text-white m-l">Pudo</span>
                    </div>
                </div>
            </header> */}
            <section className="p">HomePage
                {/* <div className="card text-center">
                    <p className="heading">We'are Sad To See You Go!</p>
                    <div>
                        <p className="discription">You have successfully unsubscribed, youwill no longer receive this type of emails from denefits</p>
                    </div>
                </div> */}
            </section>
        </>
    )
}
export default HomePage 